exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-pim-tsx": () => import("./../../../src/pages/pim.tsx" /* webpackChunkName: "component---src-pages-pim-tsx" */),
  "component---src-pages-pimcore-connector-tsx": () => import("./../../../src/pages/pimcore-connector.tsx" /* webpackChunkName: "component---src-pages-pimcore-connector-tsx" */),
  "component---src-pages-privacy-and-cookies-policy-tsx": () => import("./../../../src/pages/privacy-and-cookies-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-and-cookies-policy-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-service-analytics-visualisation-tsx": () => import("./../../../src/pages/service/analytics-&-visualisation.tsx" /* webpackChunkName: "component---src-pages-service-analytics-visualisation-tsx" */),
  "component---src-pages-service-digital-commerce-tsx": () => import("./../../../src/pages/service/digital-commerce.tsx" /* webpackChunkName: "component---src-pages-service-digital-commerce-tsx" */),
  "component---src-pages-service-digital-design-tsx": () => import("./../../../src/pages/service/digital-design.tsx" /* webpackChunkName: "component---src-pages-service-digital-design-tsx" */),
  "component---src-pages-service-pim-systems-tsx": () => import("./../../../src/pages/service/pim-systems.tsx" /* webpackChunkName: "component---src-pages-service-pim-systems-tsx" */),
  "component---src-pages-service-pimcore-bigcommerce-connector-tsx": () => import("./../../../src/pages/service/pimcore-bigcommerce-connector.tsx" /* webpackChunkName: "component---src-pages-service-pimcore-bigcommerce-connector-tsx" */),
  "component---src-pages-service-software-development-tsx": () => import("./../../../src/pages/service/software-development.tsx" /* webpackChunkName: "component---src-pages-service-software-development-tsx" */),
  "component---src-pages-service-web-2-print-tsx": () => import("./../../../src/pages/service/web2print.tsx" /* webpackChunkName: "component---src-pages-service-web-2-print-tsx" */),
  "component---src-pages-services-pim-audit-tsx": () => import("./../../../src/pages/services/pim-audit.tsx" /* webpackChunkName: "component---src-pages-services-pim-audit-tsx" */),
  "component---src-pages-services-pim-consulting-and-implementation-tsx": () => import("./../../../src/pages/services/pim-consulting-and-implementation.tsx" /* webpackChunkName: "component---src-pages-services-pim-consulting-and-implementation-tsx" */),
  "component---src-pages-services-pim-content-creation-and-enrichment-tsx": () => import("./../../../src/pages/services/pim-content-creation-and-enrichment.tsx" /* webpackChunkName: "component---src-pages-services-pim-content-creation-and-enrichment-tsx" */),
  "component---src-pages-services-pim-ecommerce-data-migration-tsx": () => import("./../../../src/pages/services/pim-ecommerce-data-migration.tsx" /* webpackChunkName: "component---src-pages-services-pim-ecommerce-data-migration-tsx" */),
  "component---src-pages-services-pim-integration-tsx": () => import("./../../../src/pages/services/pim-integration.tsx" /* webpackChunkName: "component---src-pages-services-pim-integration-tsx" */),
  "component---src-pages-services-pim-selection-tsx": () => import("./../../../src/pages/services/pim-selection.tsx" /* webpackChunkName: "component---src-pages-services-pim-selection-tsx" */),
  "component---src-pages-services-pim-support-tsx": () => import("./../../../src/pages/services/pim-support.tsx" /* webpackChunkName: "component---src-pages-services-pim-support-tsx" */),
  "component---src-pages-services-pim-taxonomy-data-modeling-tsx": () => import("./../../../src/pages/services/pim-taxonomy-data-modeling.tsx" /* webpackChunkName: "component---src-pages-services-pim-taxonomy-data-modeling-tsx" */),
  "component---src-pages-services-product-content-normalization-data-cleansing-tsx": () => import("./../../../src/pages/services/product-content-normalization-data-cleansing.tsx" /* webpackChunkName: "component---src-pages-services-product-content-normalization-data-cleansing-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-static-site-generators-tsx": () => import("./../../../src/pages/static-site-generators.tsx" /* webpackChunkName: "component---src-pages-static-site-generators-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/caseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-compare-platform-tsx": () => import("./../../../src/templates/comparePlatform.tsx" /* webpackChunkName: "component---src-templates-compare-platform-tsx" */),
  "component---src-templates-compare-search-tsx": () => import("./../../../src/templates/compareSearch.tsx" /* webpackChunkName: "component---src-templates-compare-search-tsx" */),
  "component---src-templates-platform-tsx": () => import("./../../../src/templates/platform.tsx" /* webpackChunkName: "component---src-templates-platform-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-search-pages-tsx": () => import("./../../../src/templates/searchPages.tsx" /* webpackChunkName: "component---src-templates-search-pages-tsx" */)
}

